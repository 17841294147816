<template>
  <b-container>
    <b-card no-body>
      <b-tabs card content-class="mt-3">
        <b-tab :title="$t('estimate.search-by-ticket')" :class="{ active: searchPerTicket }">
          <b-row class="mb-5">
            <b-col>
              <vue-typeahead-bootstrap
                id="projectSearch"
                class="flex-fill"
                :placeholder="$t('estimate.project-placeholder')"
                :showOnFocus="true"
                v-model="projectQuery"
                :data="projectSuggestions"
                :serializer="project => project.fullName + ' (' + project.customer.fullName + ')'"
                @hit="selectProjectHandler($event)"
              />
            </b-col>
            <b-col>
              <vue-typeahead-bootstrap
                id="ticketSearch"
                ref="ticketSearch"
                class="flex-fill"
                :placeholder="$t('estimate.ticket-placeholder')"
                :showOnFocus="true"
                v-model="ticketQuery"
                :data="ticketSuggestions"
                :serializer="ticket => (ticket.ticketId.length > 0 ? '[' + ticket.ticketId + '] ' : '') + ticket.name"
                @hit="selectTicketHandler($event)"
              />
            </b-col>
          </b-row>
          <div v-show="isTicketSelected">
            <b-row class="mb-4">
              <b-col>
                <h4>
                  {{
                    selectedTicket !== null && selectedTicket.ticketId.length > 0
                      ? '[' + selectedTicket.ticketId + '] '
                      : ''
                  }}{{ selectedTicket !== null ? selectedTicket.name : '' }}
                </h4>
              </b-col>
            </b-row>
            <b-table-simple hover small striped fixed>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>{{ $t('estimate.user') }}</b-th>
                  <b-th>
                    {{ $t('estimate.estimate') }}
                    <b-button
                      @click="toggleEstimateSpoilers"
                      size="sm"
                      variant="light"
                      class="ml-3 px-1 py-0"
                      id="toggleEstimateSpoilers"
                    >
                      <b-icon v-if="showOtherEstimates" icon="eye-slash-fill" />
                      <b-icon v-else icon="eye-fill" />
                    </b-button>
                    <b-tooltip
                      target="toggleEstimateSpoilers"
                      :title="$t('estimate.toggle-estimates')"
                      triggers="hover"
                    />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(ticketEstimate, key) in ticketEstimates" :key="key">
                  <b-th> {{ ticketEstimate.user.fullName }} </b-th>
                  <b-td v-if="showOtherEstimates"> {{ ticketEstimate.estimate }} </b-td>
                  <b-td v-else> <b-icon icon="question-circle-fill" variant="secondary" /> </b-td>
                </b-tr>
                <b-tr v-if="!userHasEstimate">
                  <b-th> {{ user !== null ? user.fullName : '' }} </b-th>
                  <b-td>
                    <b-row>
                      <b-col>
                        <b-input v-model="currentUserEstimate" :state="userEstimateIsValid(currentUserEstimate)" />
                      </b-col>
                      <b-col>
                        <b-button
                          variant="primary"
                          @click="createEstimate"
                          :disabled="!userEstimateIsValid(currentUserEstimate)"
                          >{{ $t('estimate.estimate-button') }}</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-td>
                </b-tr>
                <b-tr v-if="ticketEstimates.length > 0">
                  <b-th> {{ $t('estimate.average') }} </b-th>
                  <b-td v-if="showOtherEstimates"> {{ averageEstimate }} </b-td>
                  <b-td v-else> <b-icon icon="question-circle-fill" variant="secondary" /> </b-td>
                </b-tr>
                <b-tr>
                  <b-th> {{ $t('estimate.current-time') }} </b-th>
                  <b-td v-if="showOtherEstimates"> {{ currentTimeOnTicket }} </b-td>
                  <b-td v-else> <b-icon icon="question-circle-fill" variant="secondary" /> </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import 'vue2-datepicker/index.css'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

export default {
  name: 'MainEstimate',
  mixins: [ApiMixin, NotificationMixin],
  components: { VueTypeaheadBootstrap },
  data() {
    return {
      user: this.$store.getters.getCurrentUser,
      searchPerTicket: false,
      ticketQuery: '',
      projectQuery: '',
      customerProjectTicketMapping: null,
      selectedProject: null,
      selectedTicket: null,
      ticketEstimates: [],
      timeTrackingUnits: [],
      currentUserEstimate: 0,
      showOtherEstimates: false,
      detailedTickets: []
    }
  },
  computed: {
    ticketSuggestions() {
      if (!this.customerProjectTicketMapping) {
        return []
      }
      const suggestions = []
      this.customerProjectTicketMapping.forEach(customer => {
        customer.projects.forEach(project => {
          if (
            this.selectedProject === null ||
            project.fullName.toLowerCase().startsWith(this.selectedProject.fullName.toLowerCase())
          ) {
            project.tickets.forEach(ticket => {
              suggestions.push(ticket)
            })
          }
        })
      })
      return suggestions.filter(item => {
        return (
          item.name.toLowerCase().includes(this.ticketQuery.toLowerCase()) ||
          item.ticketId.toLowerCase().includes(this.ticketQuery.toLowerCase())
        )
      })
    },
    projectSuggestions() {
      if (!this.customerProjectTicketMapping) {
        return []
      }
      const suggestions = []
      this.customerProjectTicketMapping.forEach(customer => {
        customer.projects.forEach(project => {
          suggestions.push(project)
        })
      })
      if (this.projectQuery != null) {
        return suggestions.filter(item => {
          return item.fullName.toLowerCase().includes(this.projectQuery.toLowerCase())
        })
      } else {
        return suggestions
      }
    },
    isTicketSelected() {
      return this.selectedTicket !== null
    },
    userHasEstimate() {
      return this.ticketEstimatesContainsUser(this.ticketEstimates)
    },
    detailedTicketHasEstimateFromUser() {
      return this.detailedTickets.map(detailedTicket => {
        return this.ticketEstimatesContainsUser(detailedTicket.ticketEstimates)
      })
    },
    detailedTicketAverages() {
      return this.detailedTickets.map(detailedTicket => {
        return this.computeAverageEstimate(detailedTicket.ticketEstimates)
      })
    },
    detailedTicketCurrentTimes() {
      return this.detailedTickets.map(detailedTicket => {
        return this.computeCurrentTime(detailedTicket.timeTrackingUnits)
      })
    },
    averageEstimate() {
      return this.computeAverageEstimate(this.ticketEstimates)
    },
    currentTimeOnTicket() {
      return this.computeCurrentTime(this.timeTrackingUnits)
    }
  },
  mounted() {
    this.loadCustomerProjectTicketMapping()
  },
  methods: {
    loadCustomerProjectTicketMapping() {
      let self = this
      this.getRequest(
        '/table/customers_projects_tickets',
        new RequestConfig()
          .onSuccess(res => {
            self.customerProjectTicketMapping = res.data
          })
          .onError(() => {
            self.customerProjectTicketMapping = {}
          })
      )
    },
    selectTicketHandler(item) {
      if (!item) {
        this.selectedTicket = null
      } else {
        this.selectedTicket = item
        this.getDetailedTicket(item.id)
      }
    },
    selectProjectHandler(item) {
      if (!item) {
        this.projectQuery = ''
        this.selectedProject = null
      } else {
        this.projectQuery = item.fullName
        this.selectedProject = item
        this.getDetailedTickets(item.id)
      }
      this.ticketQuery = ''
      this.selectedTicket = null
      this.$refs.ticketSearch.inputValue = ''
    },
    getDetailedTicket(id) {
      let self = this
      this.getRequest(
        'ticket/' + id + '/detailed',
        new RequestConfig()
          .onSuccess(res => {
            self.ticketEstimates = res.data.ticketEstimates
            self.ticketEstimates.forEach(ticketEstimate => {
              ticketEstimate.estimate = ticketEstimate.estimateTimes100 / 100
            })
            self.showOtherEstimates = self.userHasEstimate
            self.timeTrackingUnits = res.data.timeTrackingUnits
          })
          .onError(() => {
            self.ticketEstimates = []
            self.timeTrackingUnits = []
          })
      )
    },
    getDetailedTickets(projectId) {
      let self = this
      this.getRequest(
        'project/' + projectId + '/detailedTickets',
        new RequestConfig()
          .onSuccess(res => {
            self.detailedTickets = res.data
            self.detailedTickets.forEach(detailedTicket => {
              detailedTicket.ticketEstimates.forEach(ticketEstimate => {
                ticketEstimate.estimate = ticketEstimate.estimateTimes100 / 100
              })
            })
            self.showOtherEstimates = false
          })
          .onError(() => {
            self.detailedTickets = []
          })
      )
    },
    userEstimateIsValid(estimate) {
      return estimate !== 0 && RegExp('^\\d+(\\.\\d+)?$').test(estimate)
    },
    createEstimate() {
      const ticketEstimate = {
        user: this.user,
        ticket: this.selectedTicket,
        estimateTimes100: this.currentUserEstimate.replace(',', '.') * 100
      }
      let self = this
      this.postRequest(
        '/ticket_estimates',
        ticketEstimate,
        new RequestConfig().onSuccess(res => {
          let newTicketEstimate = res.data
          newTicketEstimate.estimate = newTicketEstimate.estimateTimes100 / 100
          self.ticketEstimates.push(newTicketEstimate)
          self.showOtherEstimates = true
          self.currentUserEstimate = 0
        })
      )
    },
    toggleEstimateSpoilers() {
      this.showOtherEstimates = !this.showOtherEstimates
    },
    ticketEstimatesContainsUser(ticketEstimates) {
      if (ticketEstimates.length === 0) {
        return false
      }
      return ticketEstimates.some(ticketEstimate => {
        return ticketEstimate.user.userName === this.user.userName
      })
    },
    computeAverageEstimate(ticketEstimates) {
      let sum = 0
      ticketEstimates.forEach(ticketEstimate => {
        sum += ticketEstimate.estimate
      })
      if (sum !== 0) {
        sum = (sum / ticketEstimates.length).toFixed(2)
      }
      return sum
    },
    computeCurrentTime(timeTrackingUnits) {
      let sum = 0
      timeTrackingUnits.forEach(timeTrackingUnit => {
        if (timeTrackingUnit.reviewTimeTrackingUnit !== null) {
          sum += timeTrackingUnit.reviewTimeTrackingUnit.durationInMinutes
        } else {
          sum += timeTrackingUnit.durationInMinutes - timeTrackingUnit.breakDurationInMinutes
        }
      })
      // divide minutes down to days
      return (sum / (60 * 24)).toFixed(2)
    },
    sendEstimates() {
      const ticketEstimates = this.detailedTickets
        .filter(detailedTicket => {
          return (
            'newEstimate' in detailedTicket && detailedTicket.newEstimate !== null && detailedTicket.newEstimate !== 0
          )
        })
        .map(detailedTicket => {
          return {
            user: this.user,
            ticket: detailedTicket,
            estimateTimes100: detailedTicket.newEstimate.replace(',', '.') * 100
          }
        })
      let self = this
      this.postRequest(
        '/ticket_estimates/list',
        ticketEstimates,
        new RequestConfig().onSuccess(res => {
          res.data.forEach(newTicketEstimate => {
            newTicketEstimate.estimate = newTicketEstimate.estimateTimes100 / 100
            self.detailedTickets.forEach(detailedTicket => {
              if (detailedTicket.id === newTicketEstimate.ticket.id) {
                detailedTicket.ticketEstimates.push(newTicketEstimate)
                delete detailedTicket.newEstimate
              }
            })
          })
          self.showOtherEstimates = true
        })
      )
    }
  }
}
</script>
